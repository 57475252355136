import React, { useEffect } from "react";
import { filter, find, first, get, includes, map, sortBy } from "lodash";
import UserAvatar from "../UserAvatar";
import TiersChart from "./tiers-chart";
import { getMemeberSinceDate } from "../../utils/user";
import { getStoreCreditBalanceFromUser } from "../../logic/user/getters/getStoreCreditBalanceFromUser";

import * as styles from "./index.module.scss";

export default (props) => {
  const { T, business, user, businessAppConfiguration = {}, appStyles } = props;
  if (user.loggedIn) {
    useEffect(() => {
      props.loadLoyaltyProfile();
    });
  }

  const points = get(
    user,
    "loyaltyProfile.data.rewardPointBalance.balance",
    "-",
  );
  const visitCount = get(user, "loyaltyProfile.data.visitCount", "-");

  const tiers = filter(
    get(user, "loyaltyProfile.data.accumulativeCouponPoilcyRemainders"),
    (policy) => policy.type === "TIERS",
  );

  const nextTierData = first(
    sortBy(filter(tiers, (policy) => policy.remainder > 0), "remainder"),
  );

  const currentTierId = get(find(tiers, "isInGroup"), "groupId");

  const groupIds = get(user, "loyaltyProfile.data.groupIds");

  const groups = filter(business.groups, ({ id }) => includes(groupIds, id));

  const storeCreditBalance = getStoreCreditBalanceFromUser(user);

  return (
    <div className={styles.LoyaltyProfileContainer}>
      <div className={styles.UserDetails}>
        <UserAvatar
          size={50}
          name={get(user, "userDetails.data.name", "")}
          avatarUrl={get(user, "userDetails.data.avatarImage.url")}
          appStyles={appStyles}
          style={appStyles.rtl ? { marginLeft: 16 } : { marginRight: 16 }}
        />
        <div>
          <div>{get(user, "userDetails.data.name", "")}</div>
          <div className={styles.MemberSince}>
            <small>{getMemeberSinceDate(user, T)}</small>
          </div>
        </div>
      </div>
      <TiersChart
        T={T}
        currencySymbol={business.currencySymbol}
        className={styles.TiersChart}
        currentTierId={currentTierId}
        nextTierData={nextTierData}
        groups={business.groups}
        appStyles={appStyles}
      />
      <div className={styles.UserGroups}>
        {map(groups, (group) => (
          <Chip
            label={group.name}
            style={{
              backgroundColor: group.badgeBackgroundColor,
              color: group.badgeTextColor,
            }}
          />
        ))}
      </div>
      <div className={styles.UserStats}>
        {!appStyles.hideVisitCount && (
          
            <div className={styles.StatRecord}>
              <div className={styles.StatValue}>{visitCount}</div>
              <div className={styles.StatLabel}>{T("Visits")}</div>
            </div>
          )}
          {businessAppConfiguration.hasPointBank && (
            <>
              <div className={styles.Divider} />
              <div className={styles.StatRecord}>
                <div className={styles.StatValue}>{points?.toFixed(0)}</div>
                <div className={styles.StatLabel}>{T("Points")}</div>
                {T("{pointsRedemptionDescription}") !==
                  "{pointsRedemptionDescription}" && (
                  <div className={styles.MemberSince}>
                    {T("{pointsRedemptionDescription}")}
                  </div>
                )}
              </div>
            </>
          )}
          {storeCreditBalance > 0.01 && (
            <>
              <div className={styles.Divider} />
              <div className={styles.StatRecord}>
                <div className={styles.StatValue}>{`${business.currencySymbol}${storeCreditBalance}`}</div>
                <div className={styles.StatLabel}>{T("Store Credit")}</div>
              </div>
            </>
          )}
      </div>
    </div>
  );
};

const Chip = ({ label, style }) => {
  return (
    <div className={styles.Chip} style={style}>
      <div className={styles.ChipLabel}>{label}</div>
    </div>
  );
};
